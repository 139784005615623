import { ChangeEvent, useState, useRef } from "react";
import Icons from "../constants/Icons";
import { END_OF_STREAM } from "../constants/Constants";
import Loader from "../components/shared/Loader.react";
import ActionCableConsumer from "../shared/ActionCableConsumer.react";

export interface TextGeneratorProps {
  context?: string;
  inputToFillId?: string;
  basePrompt?: string;
  onSubmitGeneratedText?: (text: string) => void;
  forceToUseBasePrompt?: boolean;
  placeholder: string;
  textLength?: string;
  customTextLength?: number;
  prePromptKey?: string;
}

const i18n = (key: string, opts: any = {}): string => {
  return I18n.t(`react.text_generator.${key}`, opts);
};

const TextGenerator: React.FC<TextGeneratorProps> = ({
  context,
  basePrompt,
  inputToFillId,
  forceToUseBasePrompt,
  onSubmitGeneratedText,
  placeholder,
  textLength,
  customTextLength,
  prePromptKey,
}) => {
  const [generatedText, setGeneratedText] = useState("");
  const [textGenerationPending, setTextGenerationPending] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(null);
  const [freePrompt, setFreePrompt] = useState(!basePrompt);
  const [currentTextLength, setCurrentTextLength] = useState(textLength);
  const [currentCustomTextLength, setCurrentCustomTextLength] = useState<number | null>(customTextLength);

  const [subscription, setSubscription] = useState(null);
  const [streamConnected, setStreamConnected] = useState(false);

  const inputResultRef = useRef<HTMLTextAreaElement>(null);
  const isWebsite = context === "website";

  const computedPrompt = (): string => {
    if (!basePrompt || freePrompt) return currentPrompt;

    return `${basePrompt} ${currentPrompt}`;
  };

  const onReceived = (payload: any): void => {
    const { type, word, full_completion } = payload;
    if (type === "too_many_requests") {
      setGeneratedText(i18n("too_many_requests"));
      setTextGenerationPending(false);
      return;
    }
    if (type !== "new_completion_word") return;
    if (word === END_OF_STREAM) setTextGenerationPending(false);
    setGeneratedText(full_completion);
    if (inputResultRef?.current) inputResultRef.current.scrollTop = inputResultRef.current.scrollHeight;
  };

  const submitPrompt = (e?: any): void => {
    if (textGenerationPending) return null;
    if (e) e.preventDefault();
    const prompt = computedPrompt();
    if (!prompt || prompt.trim().length === 0) return;

    setGeneratedText("");
    setTextGenerationPending(true);
    subscription.send({
      action: "completion_request",
      prompt,
      length: currentTextLength,
      custom_length: currentCustomTextLength,
      pre_prompt_key: prePromptKey
    });
  };

  const submitGeneratedText = (e: any): void => {
    if (e) e.preventDefault();
    if (inputToFillId) {
      const element = document.getElementById(inputToFillId) as HTMLInputElement;
      if (element) {
        element.value = generatedText;
        element.dispatchEvent(new Event("change"));
      }
    }
    if (onSubmitGeneratedText) onSubmitGeneratedText(generatedText);
  };

  const renderFreePromptCheckbox = (): JSX.Element => {
    if (!basePrompt || forceToUseBasePrompt) return null;

    return <div className="form-check float-end">
      <label className="form-check-label">
        <input type="checkbox" className="form-check-input" checked={freePrompt} onChange={(e: ChangeEvent<HTMLInputElement>): void => setFreePrompt(e.target.checked)} />
        { i18n("free_prompt") }
      </label>
    </div>;
  };

  const renderPromptInput = (): JSX.Element => {
    return <div className="mb-3">
      <label className="form-label">
        { basePrompt && !freePrompt ? basePrompt : i18n("prompt") }
      </label>
      <input
        onKeyUp={(e: any): void => e.key === "Enter" && submitPrompt()}
        type="text"
        className="form-control"
        value={currentPrompt || ""}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => setCurrentPrompt(e.target.value)}
        placeholder={!freePrompt && placeholder || ""}
        autoFocus/>
    </div>;
  };

  const handleCustomTextLengthChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setCurrentCustomTextLength(parseInt(e.target.value));
  };

  const handleTextLengthChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    setCurrentTextLength(e.target.value);
    if (e.target.value !== "custom") setCurrentCustomTextLength(null);
  };

  const customInput = (): JSX.Element => {
    return (
      <div className="col-auto">
        <input type="number"
          className="form-control"
          value={ currentCustomTextLength }
          placeholder={ i18n("custom_length_placeholder") }
          onChange={handleCustomTextLengthChange} />
      </div>
    );
  };


  const renderLengthSelection = (): JSX.Element => {
    const values = ["brief", "short", "medium", "long", "very_long", "custom"];
    const options = values.map(value => {
      return <option value={value} key={value}>{i18n(`length_selection.${value}`)}</option>;
    });
    return (
      <div className="mb-3 row g-2 align-items-center">
        <label className="col-form-label col-auto" htmlFor="text_length">{ i18n("text_length") }:</label>
        <div className="col-auto">
          <select name="text_length"
            className="form-select"
            value={ currentTextLength || "medium"}
            onChange={handleTextLengthChange}>
            { options }
          </select>
        </div>
        { currentTextLength == "custom" && customInput() }
      </div>
    );
  };

  const renderSubmitPrompt = (): JSX.Element => {
    return <button type="button" className="btn btn-primary" onClick={submitPrompt} disabled={textGenerationPending || !currentPrompt?.length}>
      <i className="fa-regular fa-sparkle"></i>{"\u00A0"}{ i18n("generate_with_chat_gpt") }
    </button>;
  };

  const renderResult = (): JSX.Element => {
    const disabledClass = textGenerationPending ? "disabled" : null;
    const hiddenClass = generatedText === "" ? "d-none" : null;

    return <div className={hiddenClass}>
      <label className="form-label">
        { i18n("result") }
      </label>
      <textarea ref={inputResultRef} className={`form-control mb-20 ${disabledClass}`} rows={10} value={generatedText || ""} onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => setGeneratedText(e.target.value)}></textarea>
    </div>;
  };

  const renderUseGeneratedText = (): JSX.Element => {
    if (!generatedText || generatedText === "" || textGenerationPending) return null;

    return <button className="btn btn-primary float-end" onClick={submitGeneratedText} style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
      <span className="svg-in-btn">{ Icons.propagateTextIcon() }</span>{"\u00A0"}{ i18n("populate_with_result") }
    </button>;
  };

  return <ActionCableConsumer
    channel={ isWebsite ? "WebsiteTextCompletionChannel" : "BackOfficeTextCompletionChannel" }
    onSubscriptionAvailable={setSubscription}
    onReceived={onReceived}
    onConnected={(): void => setStreamConnected(true)}
  >
    {
      streamConnected ?
        <div>
          { renderFreePromptCheckbox() }
          { renderPromptInput() }
          { !isWebsite && renderLengthSelection() }
          { renderResult() }
          { renderSubmitPrompt() }
          { renderUseGeneratedText() }
        </div> : <Loader/>
    }
  </ActionCableConsumer>;
};

export default TextGenerator;
