import { Component } from "react";

interface Props {
  channel: string;
  params?: any;

  onSubscriptionAvailable?: (subscription: any) => void;
  onReceived?: (data: any) => void;
  onInitialized?: () => void;
  onConnected?: () => void;
  onDisconnected?: () => void;
  onRejected?: () => void;
}

interface State {
  initialized: boolean;
  connected: boolean;
  subscription?: any;
}

export default class ActionCableConsumer extends Component<Props, State> {
  private cable: any;
  private mounted: boolean;

  constructor(props: Props) {
    super(props);

    this.cable = this.findExistingCable() || ActionCable.createConsumer(window["eventmakerWebsocketEndpoint"]);
    this.mounted = true;
    this.state = {
      initialized: false,
      connected: false
    };
  }

  componentDidMount(): void {
    this.initSubscription();
  }

  componentWillUnmount(): void {
    this.mounted = false;
    this.cable.disconnect();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (!this.mounted) return;

    const { initialized, subscription, connected } = this.state;
    if (initialized && subscription && connected && (!prevState.initialized || !prevState.subscription || !prevState.connected)) {
      const { onSubscriptionAvailable } = this.props;
      onSubscriptionAvailable && onSubscriptionAvailable(subscription);
    }
  }

  findExistingCable(): any {
    // if websocket connection was already initialized in the main js file, just use it
    return window["mobicheckin"] && window["mobicheckin"]["websocketApp"] && window["mobicheckin"]["websocketApp"]["cable"];
  }

  initSubscription(): void {
    const { channel, params, onReceived, onInitialized, onConnected, onDisconnected, onRejected } = this.props;

    const options = { ...params, channel };

    const subscription = this.cable.subscriptions.create(options, {
      received: (data: any) => {
        onReceived && onReceived(data);
      },
      initialized: () => {
        this.setState({ initialized: true });
        onInitialized && onInitialized();
      },
      connected: () => {
        this.setState({ connected: true });
        onConnected && onConnected();
      },
      disconnected: () => {
        onDisconnected && onDisconnected();
      },
      rejected: () => {
        onRejected && onRejected();
      }
    });

    this.setState({ subscription });
  }

  render(): React.ReactNode {
    return this.props.children;
  }
}
