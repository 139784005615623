import { useState } from "react";
import TextGenerator, { TextGeneratorProps } from "./TextGenerator.react";
import { Modal } from "react-bootstrap";
import "./style.scss";

interface ModalProps {
  buttonLabel: string;
  modalTitle: string;
  buttonClassesOverride?: string;
  modalSize?: "lg"|"sm"|"xl";
}

interface Props extends TextGeneratorProps, ModalProps {}

const TextGeneratorButton: React.FC<Props> = ({
  modalTitle,
  buttonLabel,
  onSubmitGeneratedText,
  buttonClassesOverride,
  modalSize,
  ...props
}) => {
  if (!window["eventmakerWebsocketEndpoint"]) return null;
  if (props.context !== "website" && !window["ReactGlobalProps"]?.event_text_generation_enabled) return null;

  const [isOpen, setIsOpen] = useState(false);

  const openModal = (): void => setIsOpen(true);
  const closeModal = (): void => setIsOpen(false);
  const onSubmitGeneratedTextOverride = (generatedText: string): void => {
    if (onSubmitGeneratedText) onSubmitGeneratedText(generatedText);

    closeModal();
  };

  const modal = <Modal show={isOpen} size={ modalSize || "lg" } onHide={closeModal} id="create-accesspoint-modal">
    <Modal.Header>
      <h4 className="modal-title">{ modalTitle }</h4>
      <button type="button" onClick={ closeModal } className="btn-close" aria-label={I18n.t("close")}></button>
    </Modal.Header>
    <Modal.Body style={{ overflow: "auto" }}>
      <TextGenerator onSubmitGeneratedText={onSubmitGeneratedTextOverride} { ...props } />
    </Modal.Body>
  </Modal>;

  const modalButton = <a href="#" className={ buttonClassesOverride || "btn btn-primary" } onClick={openModal} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <i className="fa-regular fa-sparkle"></i>{"\u00A0"}{ buttonLabel }
  </a>;

  return <>
    { modal }
    { modalButton }
  </>;
};

export default TextGeneratorButton;
